<route>
{
  "meta": {
    "permission": [
      "customer.view_contact || supplier.view_suppliercontact"
    ]
  }
}
</route>

<template>
  <div>
    <dialcontacts
      v-model="dialog"
      :to-edit="toEdit"
      @done="reload = true"
      :id="value"
      :mode="mode"
    />
    <i-table
      v-if="mode === 'customer'"
      dontSearch
      :title="$tc('contact', 2)"
      :headers="headers"
      app="customer.contact"
      api="customer.contact"
      :opt="{ customer: value }"
      :reload.sync="reload"
      @click:create="open()"
      @click:edit="open($event)"
    >
    </i-table>
    <i-table
      v-if="mode === 'supplier'"
      dontSearch
      :title="$tc('contact', 2)"
      :headers="headers"
      app="supplier.suppliercontact"
      api="supplier.contact"
      :opt="{ personal_info: value }"
      :reload.sync="reload"
      @click:create="open()"
      @click:edit="open($event)"
    >
    </i-table>
  </div>
</template>
<script>
import dialcontacts from '../contact/modal.vue'
export default {
  components: {
    dialcontacts
  },
  props: {
    value: {
      type: Number
    },
    mode: {
      type: String
    }
  },
  data() {
    return {
      reload: false,
      dialog: false,
      toEdit: null
    }
  },
  computed: {
    headers() {
      return [
        {
          text: this.$tc('first_name', 1),
          value: 'first_name'
        },
        {
          text: this.$t('last_name'),
          value: 'last_name'
        },
        {
          text: this.$tc('email', 1),
          value: 'email'
        },
        {
          text: this.$tc('phone', 1),
          value: 'phone'
        },
        {
          text: this.$t('action'),
          value: 'actions'
        }
      ]
    }
  },
  methods: {
    open(item) {
      this.toEdit = item === undefined ? null : item
      this.dialog = true
    }
  }
}
</script>
